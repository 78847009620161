import orderApi from "@/api/orderApi";
import paymentApi from "@/api/paymentApi";
import images from "@/assets/images";
import Breadcrumb from "@/components/common/Breadcrumb";
import OrderCart from "@/components/common/OrderCart";
import { CartForm } from "@/components/pages/Cart/CartForm";
import NewDeliveryInformation from "@/components/pages/Cart/NewDeliveryInformation";
import { TotalCost } from "@/components/pages/Cart/TotalCost";
import {
  CartState,
  ICartProduct,
  IICartStateRecoil,
  clearCart,
} from "@/recoil/cartState";
import selectedVoucherState from "@/recoil/selectedVoucherState";
import cartSelector from "@/recoil/selectors/cartSelector";
import { shippingDataState } from "@/recoil/shippingDataStae";
import { selectedAddressState } from "@/recoil/userAddressState";
import { ItemOrderRequest, OrderRequest } from "@/type/request/order";
import { OrderResponse, OrderVer2ResponseType } from "@/type/response/order";

import constants from "@/utils/constants";
import { formatCurrency } from "@/utils/data";
import routes from "@/utils/routes";
import { getUtmData } from "@/utils/tracking";
import _ from "lodash";
import { Divider } from "antd";
import Scrollbars from "rc-scrollbars";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import toast from "react-hot-toast";
import useWindowSize from "@/hooks/useWindowSize";
import returnError from "@/utils/returnError";

export default function Cart() {
  const [isLoading, setIsLoading] = useState(false);
  const cart = useRecoilValue<IICartStateRecoil>(CartState);
  const [buy, setBuy] = useState<boolean>(false);
  const size = useWindowSize();
  const {
    totalNumberProducts,
    totalPrice,
    totalThrifty,
    // productIdsInCart
  } = useRecoilValue(cartSelector);

  const { t } = useTranslation();
  const [valueRadio, setValueRadio] = React.useState<number>(-1);
  const [shippingMethod, setShippingMethod] = useState<number>(-1);
  const checkAccessLogin = localStorage.getItem(constants.ACCESS_TOKEN);
  const dataProfile = localStorage.getItem(constants.PROFILE);
  const profile = dataProfile ? JSON.parse(dataProfile) : "";

  const { fullName, phone, mainAddress } = profile || {};
  const { provinceCity, district, ward, street, houseNumber } =
    mainAddress || {};

  // const isDataDeliveryInformationFull = [
  //   fullName,
  //   phone,
  //   provinceCity,
  //   district,
  //   ward,
  // ].every(Boolean);

  const DataDeliveryInformation = {
    name: fullName || "",
    phone: phone || "",
    address: [houseNumber, street, ward, district, provinceCity]
      .filter((el) => el)
      .join(", "),
  };

  const breadcrumbItems = [
    {
      href: routes.HOME,
      title: "Trang chủ",
    },
    {
      href: routes.CART,
      title: "Giỏ hàng",
    },
  ];

  // const checkProductInFlashSales = (productId: string | number) => {
  //   return (
  //     productIdsInFlashSales?.some((item) => item.id === Number(productId)) ||
  //     false
  //   );
  // };

  const [selectedVoucher, setSelectedVoucher] =
    useRecoilState(selectedVoucherState);
  const [shippingData] = useRecoilState(shippingDataState);
  const selectedAddress = useRecoilValue(selectedAddressState);
  const setCart = useSetRecoilState<IICartStateRecoil>(CartState);
  const [listProductOutStock, setListProductOutStock] = useState<number[]>([]);

  const navigate = useNavigate();
  let latestOrderId = -1;
  const createLinkVNPayStep = async (order_code: string) => {
    try {
      await paymentApi
        .post({ order_code: order_code })
        .then((res) => {
          window.location.replace(res?.data?.url);
        })
        .finally(() => {
          if (setIsLoading) setIsLoading(false);
        })
        .catch((error) => console.log(error));
    } catch (errors) {
      console.log(errors);
    }
  };

  const scrollToTop = () => {
    const head = document.getElementById("header");
    head?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const onSubmit = async () => {
    /**
     * -1, undefi là không được thanh toán
     * 0 là thanh toán VPAY-QR
     * 1 là Thanh toán nhận hàng
     */
    if (!profile) {
      toast.error("Vui lòng đăng nhập để đặt hàng");
      scrollToTop();
      return;
    }
    if (!selectedAddress || !shippingData) {
      toast.error("Vui lòng nhập thông tin địa chỉ để có thể đặt hàng");
      scrollToTop();
      return;
    }
    if (!_.isEmpty<ICartProduct[]>(cart.cartProducts)) {
      if (setIsLoading) setIsLoading(true);
      let formatArray: ItemOrderRequest[] = [];
      cart.cartProducts?.forEach((element: ICartProduct) => {
        formatArray.push({
          product_id: element.id.toString(),
          quanity: element.quantity,
        });
      });

      const formatBaseData: OrderRequest = {
        data: {
          payment_method: "",
          items: formatArray,
          user_vouchers:
            selectedVoucher?.map((item) => item?.voucherId) || null,
          shipping_method: shippingData?.type,
          shipping_fee: shippingData?.fee,
          user_address_id: selectedAddress?.id,
          shipping_data: {
            from_district_id: shippingData?.data?.fromDistrictId,
            from_ward_code: shippingData?.data?.fromWardCode,
            service_id: shippingData?.data?.serviceId,
            service_type_id: shippingData?.data?.serviceTypeId,
            to_district_id: shippingData?.data?.toDistrictId,
            to_ward_code: shippingData?.data?.toWardCode,
            weight: shippingData?.data?.weight,
          },
        },
      };

      const utmData = getUtmData();
      switch (Number(valueRadio)) {
        case 0:
          const formatDataBanking = {
            data: {
              ...formatBaseData.data,
              ...utmData,
              payment_method: "VNPay",
            },
          };
          try {
            await orderApi
              .post(formatDataBanking)
              .then((res) => {
                latestOrderId = res.data.order.id;
                const order: Partial<OrderResponse & OrderVer2ResponseType> =
                  res.data?.order || "";
                const orderCode = order?.orderCode || "";
                createLinkVNPayStep(orderCode);
                setSelectedVoucher([]);
              })
              .finally(() => {
                if (setIsLoading) setIsLoading(false);
              })
              .catch((error) => {
                if (error.response.data.error.status !== 200) {
                  toast.error(
                    `Đặt hàng thất bại: ${returnError(
                      error.response.data.error.message
                    )}`
                  );
                  return;
                }
                navigate(`${routes.ORDER_FAIL}/${latestOrderId}`);
              });
          } catch (errors) {
            console.log(errors);
          }
          break;
        case 1:
          const formatDataCod = {
            data: {
              ...formatBaseData.data,
              ...utmData,
              payment_method: "Thanh toán khi nhận hàng",
            },
          };
          try {
            await orderApi
              .post(formatDataCod)
              .then(async (res) => {
                latestOrderId = res.data.order.id;
                const order = res?.data?.order || "";
                await new Promise((_: any) => {
                  setTimeout(() => {
                    setCart((prev) => ({
                      ...prev,
                      cartProducts: clearCart(),
                    }));
                    setSelectedVoucher([]);
                    navigate(`${routes.ORDER_SUCCESS}/${order?.id}`);
                  }, 1000);
                });
              })
              .finally(() => {
                if (setIsLoading) setIsLoading(false);
              })
              .catch((error) => {
                if (error.response.data.error.status !== 200) {
                  toast.error(
                    `Đặt hàng thất bại: ${returnError(
                      error.response.data.error.message
                    )}`
                  );
                  return;
                }
                navigate(`${routes.ORDER_FAIL}/${latestOrderId}`);
              });
          } catch (errors) {
            console.log(errors);
          }

          break;
        default:
          return;
      }
    }
  };
  const handleOutStock = (id: number) => {
    setListProductOutStock((prev) => [...prev, id]);
  };
  const handleRemoveOutStock = (id: number) => {
    const newList = listProductOutStock?.filter((item) => item !== id);
    setListProductOutStock(newList);
  };
  const totalAmountDecrease = selectedVoucher?.reduce((acc, item) => {
    const amount = item?.amountDecrease ? Number(item.amountDecrease) : 0;
    return acc + amount;
  }, 0);
  const totalMinOrder = selectedVoucher?.reduce((acc, item) => {
    const amount = item?.minimumOrderAmount
      ? Number(item.minimumOrderAmount)
      : 0;
    return acc + amount;
  }, 0);
  const showedTotalPrice =
    totalPrice -
    (totalMinOrder < totalPrice ? Number(totalAmountDecrease) : 0) +
    (shippingData?.fee ? Number(shippingData?.fee) : 30000);

  return (
    <section>
      <Breadcrumb items={breadcrumbItems} />
      {DataDeliveryInformation && checkAccessLogin ? (
        <div className="pt-[3.24px] pb-[8px] bg-[#f7f7f7] md:py-0 md:bg-white">
          <NewDeliveryInformation />
        </div>
      ) : (
        <>
          <div className="p-4 text-center md:text-lg text-[#000] font-bold">
            <p>Vui lòng đăng nhập để thanh toán </p>
            <Link
              onClick={() => {
                localStorage.setItem("redirect", "/cart");
              }}
              to={"/login"}
              className="block w-full text-base underline text-[#FF9900] text-center md:text-lg"
            >
              {t("login")}
            </Link>
          </div>
        </>
      )}

      {cart?.cartProducts?.length > 0 && (
        <div className="flex items-center justify-center bg-[#f7f7f7] py-[8px]">
          <div className="flex items-center justify-center w-full bg-white">
            <div className="max-w-[1440px] px-0 md:px-[64px] xl:px-[160px]">
              <div className="bg-[#f7f7f7] xl:grid grid-cols-12 gap-[8px]">
                <div className="md:col-span-6 p-[24px_16px] md:p-[32px_0] xl:pr-[32px] xl:pl-0 bg-white">
                  {cart?.cartProducts?.length > 5 ? (
                    <Scrollbars
                      style={{ maxWidth: "100%", height: "500px" }}
                      autoHide
                      renderTrackVertical={(props) => (
                        <div {...props} className="track-vertical z-[9999]" />
                      )}
                    >
                      <div className="mr-[15px]">
                        {cart.cartProducts.map(
                          (item: ICartProduct, index: number) => (
                            <div key={index}>
                              <OrderCart
                                orderItem={item}
                                key={index}
                                handleOutStock={handleOutStock}
                                handleRemoveOutStock={handleRemoveOutStock}
                                hasTrashIcon={false}
                                inCart={true}
                              />
                              {cart.cartProducts.length - 1 !== index && (
                                <div className="w-full my-[24px]">
                                  <Divider className="h-[1px] bg-[#B0B0B0] m-0" />
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </Scrollbars>
                  ) : (
                    <>
                      {cart.cartProducts.map(
                        (item: ICartProduct, index: number) => (
                          <div key={index}>
                            <OrderCart
                              orderItem={item}
                              key={index}
                              handleOutStock={handleOutStock}
                              handleRemoveOutStock={handleRemoveOutStock}
                              hasTrashIcon={false}
                              inCart={true}
                            />
                            {cart.cartProducts.length - 1 !== index && (
                              <div className="w-full my-[24px]">
                                <Divider className="h-[1px] bg-[#B0B0B0] m-0" />
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </>
                  )}

                  {/* handle cart empty for totalNumberProduct */}
                  {cart.cartProducts.length > 0 && (
                    <div className="hidden md:block mt-4 text-[#484848] font-normal text-base md:text-[16px] ">
                      {t("total")} : {totalNumberProducts} {t("product")}
                    </div>
                  )}
                </div>
                {size?.width >= 768 && cart.cartProducts.length > 0 && (
                  <div className="md:col-span-6 md:pt-0 md:p-[0_0_32px] xl:pl-[32px] xl:pt-[32px] xl:pr-0 p-[24px_16px] bg-white">
                    <div className={`mb-8`}>
                      <CartForm
                        onValueChange={setValueRadio}
                        onShippingMethodChange={setShippingMethod}
                      />
                    </div>

                    <TotalCost
                      type="desktop"
                      changeLoading={(value: boolean) => setIsLoading(value)}
                      totalPrice={totalPrice}
                      thrifty={totalThrifty}
                      valueRadio={valueRadio}
                      shippingMethod={shippingMethod}
                      cartInfo={cart.cartProducts}
                      isOutOfStock={listProductOutStock.length > 0}
                      onSubmit={onSubmit}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {cart.cartProducts.length == 0 && (
        <div className="w-[400px] h-full mx-auto my-8">
          <img
            src={images.cartEmpty}
            alt=""
            className="w-full h-full object-cover object-center"
          />
        </div>
      )}

      {cart.cartProducts.length == 0 && (
        <div className="text-center py-2 mt-8">
          <Link
            to={"/"}
            className="block w-full text-base underline text-piper-lolot text-center"
          >
            {t("continueShopping")}
          </Link>
        </div>
      )}

      {isLoading && <LoadingPage />}
      {cart?.cartProducts?.length > 0 && (
        <div className="flex flex-col w-full z-10 pb-[8px] md:pb-[50px] bg-white  md:hidden mt-[8px]">
          {!buy ? (
            <>
              <div className="p-[8px_16px]">
                <TotalCost
                  type="mobile"
                  changeLoading={(value: boolean) => setIsLoading(value)}
                  totalPrice={totalPrice}
                  thrifty={totalThrifty}
                  valueRadio={valueRadio}
                  shippingMethod={shippingMethod}
                  cartInfo={cart.cartProducts}
                  isOutOfStock={listProductOutStock.length > 0}
                  onBuy={() => setBuy(true)}
                />
              </div>

              <div className="sticky bottom-0 bg-white w-full rounded-t-[24px] p-[8px_16px] shadow-custom">
                <div className="flex items-center justify-between">
                  <h3 className="">Tổng thanh toán:</h3>
                  <h3 className="m-0 font-[600] text-[16px] leading-[19.36px] text-[#FF9900]">
                    {formatCurrency(showedTotalPrice.toString() || "0")}đ
                  </h3>
                </div>
                <button
                  className={`p-[12px_20px] rounded-[24px] text-[14px] font-[600] leading-[16.94px] duration-300 w-full ${
                    !listProductOutStock.length
                      ? "bg-black text-white hover:text-[#FF9900]"
                      : "cursor-not-allowed bg-[#E6E6E6] text-[#818080] pointer-events-none"
                  }`}
                  type="submit"
                  onClick={() => setBuy(true)}
                >
                  Mua hàng
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="p-[24px_16px] mb-[8px]">
                <CartForm
                  onValueChange={setValueRadio}
                  onShippingMethodChange={setShippingMethod}
                />
              </div>
              <div className="p-[8px_16px]">
                <TotalCost
                  type="mobile"
                  changeLoading={(value: boolean) => setIsLoading(value)}
                  totalPrice={totalPrice}
                  thrifty={totalThrifty}
                  valueRadio={valueRadio}
                  shippingMethod={shippingMethod}
                  cartInfo={cart.cartProducts}
                  isOutOfStock={listProductOutStock.length > 0}
                  onBuy={() => setBuy(true)}
                />
              </div>
              <div className="sticky bottom-0 bg-white w-full rounded-t-[24px] p-[8px_16px] shadow-custom">
                <div className="flex items-center justify-between">
                  <h3 className="">Tổng thanh toán:</h3>
                  <h3 className="m-0 font-[600] text-[16px] leading-[19.36px] text-[#FF9900]">
                    {formatCurrency(showedTotalPrice.toString() || "0")}đ
                  </h3>
                </div>
                <button
                  className={`p-[12px_20px] rounded-[24px] text-[14px] font-[600] leading-[16.94px] duration-300 w-full ${
                    !listProductOutStock.length &&
                    valueRadio >= 0 &&
                    shippingMethod >= 0
                      ? "bg-black text-white hover:text-[#FF9900]"
                      : "cursor-not-allowed bg-[#E6E6E6] text-[#818080] pointer-events-none"
                  }`}
                  disabled={listProductOutStock.length > 0}
                  type="submit"
                  onClick={onSubmit}
                >
                  Đặt hàng
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </section>
  );
}

export function LoadingPage() {
  return (
    <>
      <div className="bg-black top-0 left-0 flex justify-center items-center absolute bg-red w-full z-50 h-full opacity-50">
        <div
          className="inline-block h-20 w-20 animate-spin rounded-full border-8 border-solid border-current  text-white border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      </div>
    </>
  );
}
