import BackToHeader from "@/components/common/BackToHeader";
import VoucherPopoverContent from "@/components/pages/Cart/TotalCost/VoucherPopoverContent";
import { useQueryUserVouchers } from "@/hooks/ReactQuery/profile.query";
// import selectedVouchersState from "@/recoil/selectedVoucherState";
import { IVoucherState } from "@/recoil/selectedVoucherState";
import { BaseData } from "@/type/base/baseData";
import { UserVouchers } from "@/type/response/profile";
import routes from "@/utils/routes";
import { useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
// import { useRecoilState } from "recoil";

function VouchersCart() {
  const location = useLocation();
  const { totalCost } = location.state || {};
  const navigate = useNavigate();

  const [voucher, setVoucher] = useState<IVoucherState[]>([]);
  const { allVouchers } = useQueryUserVouchers();
  const myVoucherList = allVouchers.filter(
    (item) =>
      (item?.attributes?.status === "UNUSED" &&
        item?.attributes?.voucher?.data?.attributes?.stock > 0) ||
      item?.attributes?.voucher?.data?.attributes?.stock === null
  );
  // const [selectedVoucher, setSelectedVoucher] = useRecoilState<IVoucherState[]>(
  //   selectedVouchersState
  // );
  const [isShowTextVoucher, setIsShowTextVoucher] = useState<boolean>(false);
  // const [isShowVoucher, setIsShowVoucher] = useState<boolean>(false);

  const onChangeVoucher = (selected: BaseData<UserVouchers> | null) => {
    if (selected === null) {
      return;
    }

    const {
      amountDecrease,
      code,
      percentDecrease,
      status,
      title,
      minimumOrderAmount,
      condition,
    } = selected.attributes.voucher.data.attributes;

    const newVoucher: IVoucherState = {
      voucherId: selected.id,
      amountDecrease: amountDecrease,
      code: code,
      percentDecrease: percentDecrease,
      status: status,
      title: title,
      minimumOrderAmount: minimumOrderAmount,
      condition: condition,
    };

    setIsShowTextVoucher(true);

    setVoucher((prev) => {
      const hasOrVoucher = prev.some((voucher) => voucher.condition === "OR");

      if (condition === "OR") {
        return [newVoucher];
      }

      if (hasOrVoucher && condition === "AND") {
        return [newVoucher];
      }

      const isVoucherExists = prev.some(
        (voucher) => voucher.voucherId === newVoucher.voucherId
      );

      if (isVoucherExists) {
        return prev.filter(
          (voucher) => voucher.voucherId !== newVoucher.voucherId
        );
      }

      return [...prev, newVoucher];
    });
  };

  const submitVoucher = () => {
    // setSelectedVoucher(voucher);
    // setIsShowVoucher(false);
    toast.success("Đã áp dụng voucher");
  };
  const handleCancleVoucher = () => {
    setVoucher([]);
  };
  return (
    <div className="bg-[#f7f7f7] py-[8px]">
      <BackToHeader
        content="Về giỏ hàng"
        onBackTo={() => navigate(routes.CART)}
      />

      <div className="flex items-center justify-center">
        <VoucherPopoverContent
          onChangeVoucher={onChangeVoucher}
          voucher={voucher}
          myVoucherList={myVoucherList}
          isShowTextVoucher={isShowTextVoucher}
          submitVoucher={submitVoucher}
          totalPrice={totalCost}
          closePopUp={() => {}}
          // closePopUp={() => setIsShowVoucher(false)}
          cancleVouchers={handleCancleVoucher}
          isNewPage
        />
      </div>
    </div>
  );
}

export default VouchersCart;
