import svgs from "@/assets/svgs";
import { useQueryAllCategories } from "@/hooks/ReactQuery/categories.query";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import useWindowSize from "@/hooks/useWindowSize";

import { Navigation } from "swiper/modules";
import { Link, useLocation } from "react-router-dom";
import { BaseData } from "@/type/base/baseData";
import { Category } from "@/type/common/category";
import routes from "@/utils/routes";
import { motion } from "framer-motion";
import { useSetRecoilState } from "recoil";
import { resetFilterTimeState } from "@/recoil/filterProduct";
interface QuickFilterComponentProps {}

const animationSettings = {
  initial: { opacity: 0, scale: 0.7 },
  whileInView: { opacity: 1, scale: 1 },
  transition: {
    type: "spring",
    duration: 1,
    delay: 0.8,
    ease: [0, 0.71, 0.2, 1.01],
  },
  viewport: { once: true },
};

export default function QuickFilterComponent({}: QuickFilterComponentProps) {
  // const [categories, setCategories] = useState()
  const size = useWindowSize();
  const { pathname } = useLocation();
  const { data: categories } = useQueryAllCategories(
    "filters[parent_category_1][id][$null]=false&sort=total_purchase:desc"
  );
  const setResetFilterTime = useSetRecoilState(resetFilterTimeState);
  // Function to chunk the categories array
  const chunkArray = (array: BaseData<Category>[], size: number) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const mobileChunks = chunkArray(categories || [], 4);
  const desktopChunks = chunkArray(categories.slice(5, 24) || [], 6);
  const handleChangeQuickFilter = () => {
    if (pathname === routes.PRODUCT) {
      setResetFilterTime(new Date().toString());
    }
  };

  if (size.width < 768) {
    return (
      <motion.div className="mx-auto max-w-[1312px]" {...animationSettings}>
        <Swiper className="mySwiper">
          {mobileChunks.map((chunk, chunkIndex) => (
            <SwiperSlide
              className="grid grid-cols-4 grid-rows-1 gap-[16px] md:gap-[32px] py-[16px] md:py-[32px] px-[16px] md:px-[64px]"
              key={chunkIndex}
            >
              {chunk.map((category, index) => (
                <Link
                  to={`${routes.PRODUCT}?categories=${category?.id} `}
                  className="flex flex-col items-center gap-[16px] relative"
                  key={index}
                  onClick={handleChangeQuickFilter}
                >
                  <div className="relative group/cat">
                    <img
                      src={category?.attributes?.banner?.data?.attributes?.url}
                      className="w-[48px] h-[48px] rounded-[16px] object-cover"
                      alt={category?.attributes?.name}
                    />
                    <div className="absolute top-0 left-0 w-full h-full z-10 bg-[#000] opacity-30 rounded-[16px] hidden group-hover/cat:block"></div>
                  </div>

                  <p className="text-[13px] font-[400] text-wrap text-center">
                    {category?.attributes?.name}
                  </p>
                </Link>
              ))}
            </SwiperSlide>
          ))}
        </Swiper>
      </motion.div>
    );
  }

  return (
    <motion.div
      className="mx-auto max-w-[1440px] relative"
      {...animationSettings}
    >
      <Swiper
        observer={true}
        observeParents={true}
        onSwiper={(swiper) => swiper.update()}
        modules={[Navigation]}
        className="mySwiper"
        navigation={size.width >= 1024}
        // navigation={{
        //   nextEl: '.sniper-button-next--flashSale',
        //   prevEl: '.sniper-button-prev--flashSale',
        //   disabledClass: 'swiper-button-disabled',
        //   hiddenClass: 'swiper-button-hidden',
        //   lockClass: 'swiper-button-lock',
        // }}
      >
        <SwiperSlide className="grid grid-cols-6 grid-rows-1 gap-[32px] py-[32px] px-[16px] md:px-[64px]">
          <div className="flex flex-col items-center gap-[16px]">
            <img
              src={svgs.highlightCategories}
              className="min-w-[96px] h-[96px] object-cover"
              // alt={category?.attributes?.name}
            />
            <p className="text-[16px] font-[700] text-wrap text-center">
              Danh mục nổi bật
            </p>
          </div>
          {categories?.slice(0, 5)?.map((category, index) => {
            return (
              <Link
                to={`${routes.PRODUCT}?categories=${category?.id} `}
                className="flex flex-col items-center gap-[16px]"
                key={index}
                onClick={handleChangeQuickFilter}
              >
                <div className="relative group/cat">
                  <img
                    src={category?.attributes?.banner?.data?.attributes?.url}
                    className="min-w-[96px] h-[96px] rounded-[16px] object-cover"
                    alt={category?.attributes?.name}
                  />
                  <div className="absolute top-0 left-0 w-full h-full z-10 bg-[#000] opacity-30 rounded-[16px] hidden group-hover/cat:block"></div>
                </div>
                <p className="text-[16px] font-[400] text-wrap text-center">
                  {category?.attributes?.name}
                </p>
              </Link>
            );
          })}
        </SwiperSlide>
        {desktopChunks?.map((chunk, index) => {
          return (
            <SwiperSlide
              className="grid grid-cols-6 grid-rows-1 gap-[32px] py-[32px] px-[16px] md:px-[64px]"
              key={index}
            >
              {chunk.map((category, index) => (
                <Link
                  to={`${routes.PRODUCT}?categories=${category?.id} `}
                  className="flex flex-col items-center gap-[16px]"
                  key={index}
                  onClick={handleChangeQuickFilter}
                >
                  <img
                    src={category?.attributes?.banner?.data?.attributes?.url}
                    className="min-w-[96px] h-[96px] rounded-[16px] object-cover"
                    alt={category?.attributes?.name}
                  />
                  <p className="text-[16px] font-[400] text-wrap">
                    {category?.attributes?.name}
                  </p>
                </Link>
              ))}
            </SwiperSlide>
          );
        })}
        {/* <div className={classNameBlock != undefined ? classNameBlock : ''}>
        {quickFilters.map((item: QuickFilter, idx: number) => (
          <div key={idx}>
            <Link
              to={`products?${paramsQueryFilter}=${item?.productLine?.data?.id ?? item?.skinAttribute?.data?.id
                } `}
              className={classNameItem}
            >
              {notUpperCase ? item?.name : item?.name?.toUpperCase()}
            </Link>
          </div>
        ))}
      </div> */}
      </Swiper>
    </motion.div>
  );
}
